import React, { useEffect, useRef, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { addClass, makePublicURL, removeClass, debounce } from "../../helpers";
// import { COD, CARD_PAYMENT } from "../../helpers/constants";
import {
  UPDATE_DISCOUNTED_PRICE,
  UPDATE_DISCOUNT_ERROR,
  fetchCartContent,
  updateCartContent,
  createGiftInfo,
  updateGiftInfo,
  deleteGiftInfo,
  updateDiscount,
  UPDATE_REFERRAL_DISCOUNT_MODE,
  SET_CART_ID,
  RESET_ADDITIONAL_OFF,
  RESET_NAT_CASH_BACK,
  // updateGiftText,
  UPDATE_APPLIED_NAT_CASH,
  // UPDATE_SHIPPING,
  UPDATE_PRODUCT_VALUE,
  UPDATE_SUBTOTAL_VALUE,
  UPDATE_TOTAL_VALUE,
  UPDATE_DISCOUNT_AMOUNT,
  UPDATE_DISCOUNT_REASON,
  UPDATE_FREE_ITEMS,
  // updatePaymentMode,
  UPDATE_SHIPPING,
  UPDATE_IS_LOADING,
  UPDATE_PAYMENT_CHARGES,
  UPDATE_REDEEMED_NAT_CASH,
  UPDATE_ADDITIONAL_OFF,
  // updateDiscountReducerValues,
} from "../../store/cart/actions";
// import { getShippingAmount } from "../../store/cart/actions";
import ProductEntry from "./ProductEntry";
import LoadingProductEntry from "./LoadingProductEntry";
import "./scss/cart.scss";
import { useCookies } from "react-cookie";
import { Spin, Switch, Checkbox } from "antd";
import { LoadingOutlined, FormOutlined } from "@ant-design/icons";
import { cartAPI } from "../../helpers/api";
// import { Edit, TrainRounded } from "@material-ui/icons";
import { getCustomerNatCash, checkNatCashApplicable } from "../../store/login/actions";
import BillDetailsSkeletonLoader from "./BillDetailsSkeletonLoader";
import { pushEventsToCt } from "../../helpers/ct";

export default function EditableCartSidebar({ cartID }) {
  const antIcon = <LoadingOutlined style={{ fontSize: 20, color: "white" }} spin />;
  // const [cookies, setCookie, removeCookie] = useCookies(["userPhone"]);
  const [cookies, setCookie] = useCookies();
  const cartSelector = useSelector((state) => state.cart);
  const paymentSelector = useSelector((state) => state.payment);
  const checkoutSelector = useSelector((state) => state.checkout);
  const { phoneNumber, mode } = useSelector((state) => state.login);
  // const [loadingTimer, setLoadingTimer] = useState(null);
  const [discountInputDisabled, setDiscountInputDisabled] = useState(false);
  const dispatch = useDispatch();
  let {
    productDetails,
    shipping,
    currency,
    // productPrices,
    discountCodeErrorMessage,
    addressDeliverabilityError,
    cartId,
    giftEnable,
    giftTo,
    giftFrom,
    giftMessage,
    doNotSendInvoice,
    checkoutId,
    // MrpTotal,
    subTotalPrice,
    paymentCharges,
    additionalOff,
    additionalOffExpiry,
    eligibleNatCash,
    eligibleNatCashExpiry,
    tempNatCashApplied,
    natCash,
    natCashApplied,
    maxApplicableNatCash,
    finalTotalPrice,
    discountAmount,
    promotionCosts,
    discountReason,
    // finalSubtotal,
    freeItems,
    isLoading,
  } = cartSelector;
  const { orderID } = checkoutSelector;
  const [isGift, setIsGift] = useState(giftEnable);
  const [sender, setSender] = useState(giftFrom);
  const [recepient, setRecepient] = useState(giftTo);
  const [isgiftMessage, setGiftMessage] = useState(giftMessage);
  const [sendInvoice, setSendInvoice] = useState(!doNotSendInvoice);
  const [showGiftForm, setShowGiftform] = useState(giftEnable);
  const [edit, setEdit] = useState(giftEnable);
  const [noteditForm, setNotEditForm] = useState(giftEnable);
  const [shouldCheckNatCash, setShouldCheckNatCash] = useState(true);
  // const [mrpDiscount, setMrpDiscount] = useState(0);
  // const [mrpDiscountPercent, setMrpDiscountPercent] = useState(0);
  // const [isShipping, setIsShipping] = useState(false);
  // const [tempPaymentCharge, setTempPaymentCharge] = useState(0);
  // const params = useQuery();

  useEffect(() => {
    setNotEditForm(giftEnable);
    setIsGift(giftEnable);
    setShowGiftform(giftEnable);
    setEdit(giftEnable);
    setSender(giftFrom);
    setRecepient(giftTo);
    setGiftMessage(giftMessage);
    setSendInvoice(!doNotSendInvoice);
  }, [giftEnable, giftFrom, giftTo, giftMessage, doNotSendInvoice]);

  // useEffect(() => {
  //   // if (paymentSelector.paymentMode === COD) {
  //   //   setTempPaymentCharge(PaymentCharges.cod_charges);
  //   // } else if (paymentSelector.paymentMode === CARD_PAYMENT) {
  //   //   setTempPaymentCharge(PaymentCharges.online_charges);
  //   // }
  //   if (checkoutId) {
  //     console.log(paymentSelector.paymentMode);
  //     dispatch(updatePaymentMode(checkoutId, paymentSelector.paymentMode));
  //   }
  // }, [checkoutId]);

  useEffect(() => {
    if (phoneNumber && mode === "verifiedUser") {
      dispatch(getCustomerNatCash());
    }
  }, [phoneNumber, mode]);

  useEffect(() => {
    const checkNatCash = async () => {
      if (cookies.access_token && tempNatCashApplied > 0 && shouldCheckNatCash) {
        const { data, statusCode } = await checkNatCashApplicable(tempNatCashApplied, cartId);
        if (statusCode === 200 && data.applicable) {
          if (data.applicable) {
            dispatch({
              type: UPDATE_APPLIED_NAT_CASH,
              payload: tempNatCashApplied,
            });
          }
          setNatCashCheckData({
            applied: data.applicable,
            loading: false,
            errorMsg: data.message,
          });
        }
        setShouldCheckNatCash(false);
      }
    };

    checkNatCash();
  }, [tempNatCashApplied]);

  const [discountCode, setDiscountCode] = useState(discountReason ? discountReason : "");
  const [finalDiscountCode, setFinalDiscountCode] = useState("");
  const discountSectionRef = useRef();
  // const giftButtonRef = useRef();
  const removeValJsRef = useRef();
  const discountFormRef = useRef();
  const discountInputRef = useRef();
  // const [activeShipping, setActiveShipping] = useState(shipping);
  const [isMobile, setIsMobile] = useState(true);
  const [natCashCheckData, setNatCashCheckData] = useState({
    applied: false,
    loading: false,
    errorMsg: "",
  });

  // getTotal(productDetails, discountPrice, shipping, paymentSelector.paymentMode, PaymentCharges)
  const [loading, setLoading] = useState(true);
  const [discountLoading, setDiscountLoading] = useState(false);

  const updateQuantity = (product, quantityIncrement) => {
    dispatch(updateCartContent(product, quantityIncrement, cartID));
  };

  const handleResize = (e) => {
    if (window.matchMedia("(max-width: 767px)").matches) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
  });

  useEffect(() => {
    if (!productDetails) {
      setLoading(true);
    } else {
      setLoading(false);
    }
    dispatch({
      type: SET_CART_ID,
      payload: cartID,
    });
  }, [productDetails, cartID, phoneNumber]);

  // useEffect(() => {
  //   const discountInStorage = params.get("disc_code")?.toUpperCase() || sessionStorage.getItem("discount");
  //   if (discountInStorage && discountInStorage !== "" && discountInStorage !== "undefined") {
  //     console.log("first");
  //     setDiscountCode(discountInStorage);
  //     addClass(discountFormRef, "form-active");
  //     addClass(removeValJsRef, "is-active");
  //     addClass(discountInputRef, "valid");
  //     setDiscountInputDisabled(true);
  //     if (phoneNumber !== "" && productPrices) handleApplyDiscount();
  //   }
  // }, [phoneNumber, productPrices]);

  useEffect(() => {
    if (discountReason) {
      if (discountCodeErrorMessage) {
        addClass(discountFormRef, "form-error");
        removeClass(discountFormRef, "form-active");
        // removeClass(discountSectionRef, "active");
        setDiscountInputDisabled(false);
      } else {
        setDiscountCode(discountReason);
        addClass(discountFormRef, "form-active");
        addClass(removeValJsRef, "is-active");
        addClass(discountInputRef, "valid");
        // addClass(discountSectionRef, "active");
        setDiscountInputDisabled(true);
      }
    }
  }, [discountReason, discountCodeErrorMessage]);

  // useEffect(() => {
  //   if (!discountCode && productDetails && phoneNumber !== "" && productPrices) {
  //     for (let item of productDetails) {
  //       if (item.isFreeProduct && !item.expiredAt) {
  //         setDiscountCode(item.discountReason);
  //         addClass(discountFormRef, "form-active");
  //         addClass(removeValJsRef, "is-active");
  //         addClass(discountInputRef, "valid");
  //         setDiscountInputDisabled(true);
  //         handleApplyDiscount(item.discountReason);
  //       }
  //     }
  //   }
  // }, [productDetails, phoneNumber, productPrices]);

  // useEffect(() => {
  //   setDiscountLoading(false);
  //   if (discountCodeErrorMessage) {
  //     addClass(discountFormRef, "form-error");
  //     removeClass(discountFormRef, "form-active");
  //     removeClass(discountSectionRef, "active");
  //     setDiscountInputDisabled(false);
  //   } else if (discountReason) {
  //     removeClass(discountFormRef, "form-error");
  //     addClass(discountFormRef, "form-active");
  //     addClass(discountSectionRef, "active");
  //     setDiscountInputDisabled(true);
  //   }
  // }, [discountReason, discountCodeErrorMessage]);

  useEffect(() => {
    if (cartID) {
      dispatch(fetchCartContent(cartID, checkoutSelector.pageInitialLoaded));
    }
  }, [cartID]);

  // useLayoutEffect(() => {
  //   setTotal(getTotal(productDetails, discountPrice, shipping, paymentSelector.paymentMode, PaymentCharges));
  // }, [productDetails, discountPrice, shipping, paymentSelector.paymentMode, PaymentCharges, isMobile]);

  // useEffect(() => {
  //   if (sendInvoice) {
  //     addClass(giftButtonRef, "green-btn");
  //   }
  //   else if ((isgiftMessage.length > 0 || sender.length > 0 || recepient.length > 0) && (!sendInvoice)) {
  //     addClass(giftButtonRef, "green-btn");
  //   }
  //   else if ((isgiftMessage.length === 0 && sender.length === 0 && recepient.length === 0) && (!sendInvoice)) {
  //     removeClass(giftButtonRef, "green-btn");
  //   }

  // }, [sender, recepient, isgiftMessage, showGiftForm, sendInvoice]);

  // const handleLogout = (e) => {
  //   removeCookie("userPhone");
  //   window.location.reload();
  // };

  const handleApplyDiscount = (localDiscount = null) => {
    setDiscountLoading(true);
    // if (loadingTimer) clearTimeout(loadingTimer);
    // setLoadingTimer(
    //   setTimeout(function () {
    //     setDiscountLoading(false);
    //   }, 5000)
    // );
    setFinalDiscountCode(discountCode);
    let payloadDiscount = "";
    if (!discountCode || discountCode === "") {
      payloadDiscount = localDiscount;
    } else {
      payloadDiscount = discountCode;
    }

    // TODO : Write code for validating Discount value
    if (phoneNumber === "") {
      // dispatch({
      //   type: UPDATE_DISCOUNT_ERROR,
      //   payload: "Please login or continue as guest first.",
      // });
      // setDiscountLoading(false);

      payloadDiscount = payloadDiscount.replace(/\s+/g, "");
      const discountPayload = {
        discount_code: payloadDiscount,
        checkout_token: cartId,
      };
      dispatch(updateDiscount(discountPayload, () => setDiscountLoading(false), mode));
    } else {
      // const productList = productDetails.map((product) => {
      //   return {
      //     product_id: product.productId,
      //     variant_id: product.variantId,
      //     price: product.productOriginalPrice,
      //     quantity: product.productQuantity,
      //     collection_id: product.collectionId,
      //   };
      // });
      // payloadDiscount = payloadDiscount.trim();
      payloadDiscount = payloadDiscount.replace(/\s+/g, "");
      const discountPayload = {
        phone_number: phoneNumber,
        // checkout_items: productList,
        // order_amount: productPrices,
        // shipping_amount: shipping,
        discount_code: payloadDiscount,
        checkout_token: cartId,
        // products: productList,
      };
      dispatch(updateDiscount(discountPayload, () => setDiscountLoading(false), mode));
    }
  };

  const discountHandler = (e) => {
    e.preventDefault();
    handleApplyDiscount();
  };

  const handleDiscountCodeChange = (e) => {
    setDiscountCode(e.target.value.toUpperCase());
    if (discountCode !== "") {
      addClass(discountFormRef, "form-active");
      addClass(removeValJsRef, "is-active");
      addClass(discountInputRef, "valid");
    } else {
      dispatch({
        type: UPDATE_DISCOUNTED_PRICE,
        payload: { discountAmount: 0.0, discountType: "", discountValue: 0.0 },
      });
      dispatch({ type: UPDATE_REFERRAL_DISCOUNT_MODE, payload: false });
      removeClass(removeValJsRef, "is-active");
      removeClass(discountFormRef, "form-error");
      removeClass(discountFormRef, "form-active");
      removeClass(discountInputRef, "valid");
      removeClass(discountInputRef, "error");
      // removeClass(discountSectionRef, "active");
    }
  };

  const handleApplyNatCash = async () => {
    // if (maxApplicableNatCash > natCash) {
    //   setNatCashCheckData({
    //     applied: false,
    //     loading: false,
    //     errorMsg: "Not Enough NatCash!",
    //   });
    //   return;
    // }
    let appliedSuccessfully = false;

    if (maxApplicableNatCash && maxApplicableNatCash > 0) {
      setNatCashCheckData({
        applied: false,
        loading: true,
        errorMsg: "",
      });

      dispatch({ type: UPDATE_IS_LOADING, payload: true });
      const { data, statusCode } = await checkNatCashApplicable(
        maxApplicableNatCash > natCash ? natCash : maxApplicableNatCash,
        cartId
      );
      if (statusCode === 200) {
        dispatch({ type: UPDATE_IS_LOADING, payload: false });
        if (data.applicable) {
          dispatch({
            type: UPDATE_APPLIED_NAT_CASH,
            payload: maxApplicableNatCash,
          });
        }

        setNatCashCheckData({
          applied: data.applicable,
          loading: false,
          errorMsg: data.message,
        });

        // dispatch(fetchCartContent(cartID, checkoutSelector.pageInitialLoaded));
        dispatch({ type: UPDATE_PRODUCT_VALUE, payload: data.costs.total });
        dispatch({ type: UPDATE_SUBTOTAL_VALUE, payload: data.costs.items_total });
        dispatch({ type: UPDATE_TOTAL_VALUE, payload: data.costs.total });
        dispatch({ type: UPDATE_DISCOUNT_AMOUNT, payload: data.costs.discount_amount });
        dispatch({ type: UPDATE_DISCOUNT_REASON, payload: data.costs.discount_reason });
        dispatch({ type: UPDATE_PAYMENT_CHARGES, payload: data.costs.payment_charges });
        dispatch({ type: UPDATE_REDEEMED_NAT_CASH, payload: data.costs.redeemed_natcash });
        dispatch({ type: UPDATE_SHIPPING, payload: data.costs.shipping });
        dispatch({ type: UPDATE_ADDITIONAL_OFF, payload: data.costs.additional_off });
        appliedSuccessfully = data.applicable;
      } else {
        setNatCashCheckData({
          applied: false,
          loading: false,
          errorMsg: "Please try again later!",
        });
      }
    }
    pushEventsToCt("NatCash Applied", {
      "Applied NatCash": maxApplicableNatCash > natCash ? natCash : maxApplicableNatCash,
      "Available NatCash": natCash,
      "Max Applicable NatCash": maxApplicableNatCash,
      Success: appliedSuccessfully,
    });
  };

  const handleRemoveNatCash = async () => {
    let removedSuccessfully = false;
    dispatch({ type: UPDATE_IS_LOADING, payload: true });
    const { data, statusCode } = await checkNatCashApplicable(0, cartId);
    dispatch({ type: UPDATE_IS_LOADING, payload: false });
    if (statusCode === 200) {
      dispatch({
        type: UPDATE_APPLIED_NAT_CASH,
        payload: 0,
      });
      setNatCashCheckData({
        applied: false,
        loading: false,
        errorMsg: "",
      });

      // dispatch(fetchCartContent(cartID, checkoutSelector.pageInitialLoaded));
      dispatch({ type: UPDATE_PRODUCT_VALUE, payload: data.costs.total });
      dispatch({ type: UPDATE_SUBTOTAL_VALUE, payload: data.costs.items_total });
      dispatch({ type: UPDATE_TOTAL_VALUE, payload: data.costs.total });
      dispatch({ type: UPDATE_DISCOUNT_AMOUNT, payload: data.costs.discount_amount });
      dispatch({ type: UPDATE_DISCOUNT_REASON, payload: data.costs.discount_reason });
      dispatch({ type: UPDATE_PAYMENT_CHARGES, payload: data.costs.payment_charges });
      dispatch({ type: UPDATE_REDEEMED_NAT_CASH, payload: data.costs.redeemed_natcash });
      dispatch({ type: UPDATE_SHIPPING, payload: data.costs.shipping });
      dispatch({ type: UPDATE_ADDITIONAL_OFF, payload: data.costs.additional_off });
      removedSuccessfully = true;
    } else {
      setNatCashCheckData({
        applied: false,
        loading: false,
        errorMsg: "Please try again later!",
      });
    }
    pushEventsToCt("NatCash Removed", {
      "Applied NatCash": maxApplicableNatCash > natCash ? natCash : maxApplicableNatCash,
      "Available NatCash": natCash,
      "Max Applicable NatCash": maxApplicableNatCash,
      Success: removedSuccessfully,
    });
  };

  const natCashHandler = async (checked) => {
    if (checked) {
      await handleApplyNatCash();
    } else {
      await handleRemoveNatCash();
    }
  };

  const handleRemoveDiscount = async () => {
    dispatch({ type: UPDATE_IS_LOADING, payload: true });
    let discountRemovedSuccessfully = false;
    let tempDiscountCode = discountCode;
    // const { data, statusCode } = await promotionsAPI.post("/v2/remove/discount", { checkout_token: cartId });
    // const { data, statusCode } = await promotionsAPI.post("/v3/remove/discount", { checkout_token: cartId });
    const { data, statusCode } = await cartAPI.post("/api/v1/discount/remove/discount", { checkout_token: cartId });
    if (statusCode === 200) {
      dispatch({ type: UPDATE_IS_LOADING, payload: false });
      if (data.status === "SUCCESS") {
        if (data.data.is_free_item_removed) {
          dispatch({
            type: UPDATE_FREE_ITEMS,
            payload: [],
          });
          dispatch(fetchCartContent(cartID, checkoutSelector.pageInitialLoaded));
        }
        dispatch({ type: UPDATE_PRODUCT_VALUE, payload: data.data.costs.total });
        dispatch({ type: UPDATE_SUBTOTAL_VALUE, payload: data.data.costs.items_total });
        dispatch({ type: UPDATE_TOTAL_VALUE, payload: data.data.costs.total });
        dispatch({ type: UPDATE_DISCOUNT_AMOUNT, payload: data.data.costs.discount_amount });
        dispatch({ type: UPDATE_DISCOUNT_REASON, payload: data.data.costs.discount_reason });
        dispatch({ type: UPDATE_SHIPPING, payload: data.data.costs.shipping });
        dispatch({ type: UPDATE_REFERRAL_DISCOUNT_MODE, payload: false });
        dispatch({ type: UPDATE_DISCOUNTED_PRICE, payload: { discountType: "", discountValue: 0.0 } });
        setDiscountCode("");
        setFinalDiscountCode("");
        setDiscountInputDisabled(false);
        // removeClass(discountSectionRef, "active");
        removeClass(discountInputRef, "valid");
        removeClass(discountInputRef, "error");
        removeClass(removeValJsRef, "is-active");
        removeClass(discountFormRef, "form-error");
        removeClass(discountFormRef, "form-active");
        discountRemovedSuccessfully = true;
      } else if (data.status !== "SUCCESS") {
        dispatch({ type: UPDATE_DISCOUNT_ERROR, payload: data.message });
      }
    } else {
      dispatch({ type: UPDATE_DISCOUNT_ERROR, payload: "Something went wrong. Please try again later." });
    }
    pushEventsToCt("Discount Code Removed", {
      "Discount Code": tempDiscountCode,
      Phone: phoneNumber ? phoneNumber : "",
      "User Type": mode === "verifiedUser" ? "Verified User" : "Guest User",
      "Checkout Token": cartId,
      "Discount Amount": 0,
      Success: discountRemovedSuccessfully,
      "Cart Token": cookies.cust_cart,
    });
  };

  // const handleEmptyDiscountChange = async () => {
  //   // remove discount
  //   // const { data, statusCode } = await cartAPI.post("/v2/checkout/remove/discount", { checkout_token: cartId });
  //   const { data, status } = await promotionsAPI.post("/v2/remove/discount", { checkout_token: cartId });
  //   if (status === 200) {
  //     // call api here
  //     if (discountCode && discountCode.replaceAll(" ", "").startsWith("FREE")) {
  //       // const { data, statusCode } = await cartAPI.post("/v2/checkout/remove_free_products", {
  //       //   checkout_token: cartId,
  //       // });
  //       dispatch(fetchCartContent(cartID, checkoutSelector.pageInitialLoaded));
  //     }
  //     dispatch({
  //       type: UPDATE_DISCOUNTED_PRICE,
  //       payload: { discountAmount: 0.0, discountType: "", discountValue: 0.0 },
  //     });
  //     // dispatch(getShippingAmount(finalSubtotal));
  //     dispatch({ type: UPDATE_SHIPPING, payload: finalSubtotal });
  //     dispatch({ type: UPDATE_REFERRAL_DISCOUNT_MODE, payload: false });
  //     removeClass(discountSectionRef, "active");
  //     // sessionStorage.removeItem("discount");
  //     setDiscountCode("");
  //     setFinalDiscountCode("");
  //     setDiscountInputDisabled(false);
  //     removeClass(discountInputRef, "valid");
  //     removeClass(discountInputRef, "error");
  //     removeClass(removeValJsRef, "is-active");
  //     removeClass(discountFormRef, "form-error");
  //     removeClass(discountFormRef, "form-active");
  //   } else {
  //     dispatch({
  //       type: UPDATE_DISCOUNT_ERROR,
  //       payload: "Failed to remove. Please try again!",
  //     });
  //   }
  // };

  const makeGiftToggle = (checked) => {
    let eventName = "Gift Note Removed";
    setIsGift(checked);
    setShowGiftform(checked);
    if (isGift && checked === false) {
      dispatch(deleteGiftInfo(checkoutId));
      setSender("");
      setRecepient("");
      setGiftMessage("");
    } else {
      const payload = {
        checkout: checkoutId,
        enable_gift: checked,
        sender: sender,
        to: recepient,
        message: isgiftMessage,
        send_invoice: !isgiftMessage,
      };
      dispatch(createGiftInfo(payload));
      eventName = "Gift Note Added";
    }
    pushEventsToCt(eventName, {
      "User Type": mode === "verifiedUser" ? "Verified User" : "Guest User",
      "Checkout Token": cartId,
    });
  };

  const editGift = () => {
    setEdit(false);
    setShowGiftform(true);
    setNotEditForm(false);
  };

  const saveGiftData = () => {
    const payload = {
      checkout: checkoutId,
      enable_gift: isGift,
      sender: sender,
      to: recepient,
      message: isgiftMessage,
      send_invoice: !sendInvoice,
    };
    dispatch(createGiftInfo(payload));
    // setShowGiftform(false)
    setIsGift(true);
    setEdit(true);
    setNotEditForm(true);
  };

  useEffect(() => {
    if (sender.length > 0 || recepient.length > 0 || isgiftMessage.length > 0 || sendInvoice) {
      // const payload = {
      //   cart: checkoutId,
      //   isGift: isGift,
      //   sender: sender,
      //   recepient: recepient,
      //   isgiftMessage: isgiftMessage,
      //   sendInvoice: sendInvoice
      // }
      // dispatch(updateGiftText(payload));

      if (checkoutId) storeGiftData(checkoutId, isGift, sender, recepient, isgiftMessage, sendInvoice);
    }
  }, [sender, recepient, isgiftMessage, sendInvoice, isGift, checkoutId]);

  const storeGiftData = useCallback(
    debounce((checkoutId, isGift, sender, recepient, isgiftMessage, sendInvoice) => {
      try {
        const payload = {
          enable_gift: isGift,
          sender: sender,
          to: recepient,
          message: isgiftMessage,
          send_invoice: !sendInvoice,
        };
        dispatch(updateGiftInfo(checkoutId, payload));
      } catch (e) {
        console.log("uff", e);
      }
    }, 2000),
    []
  );

  // useEffect(() => {
  //   let discountPriceOnMrp = MrpTotal - (finalTotalPrice + discountPrice - shipping);
  //   if (paymentSelector.paymentMode === CARD_PAYMENT) {
  //     discountPriceOnMrp = discountPriceOnMrp + PaymentCharges.online_charges;
  //   } else if (paymentSelector.paymentMode === COD) {
  //     discountPriceOnMrp = discountPriceOnMrp + PaymentCharges.cod_charges;
  //   }
  //   let discountPercentageOnMrp = ((discountPriceOnMrp * 100) / MrpTotal).toFixed(1);
  //   setMrpDiscount(discountPriceOnMrp);
  //   setMrpDiscountPercent(discountPercentageOnMrp);
  // }, [finalTotalPrice, discountPrice, MrpTotal, shipping, paymentSelector.paymentMode]);

  // useEffect(() => {
  //   if (shipping > 0) {
  //     setIsShipping(true);
  //   }
  // }, [shipping]);

  const [timer, setTimer] = useState("");
  const Ref = useRef(null);

  const [cashBackTimer, setCashBackTimer] = useState("");
  const CashBackRef = useRef(null);

  const getTimeRemaining = (e, timerType) => {
    const finalTotalPrice = Date.parse(e + "Z") - Date.parse(new Date());
    const seconds = Math.floor((finalTotalPrice / 1000) % 60);
    const minutes = Math.floor((finalTotalPrice / 1000 / 60) % 60);
    const hours = Math.floor(finalTotalPrice / 1000 / 60 / 60);
    if (finalTotalPrice <= 0 && Ref.current && additionalOffExpiry) {
      if (timerType == "addOff" && !orderID) {
        dispatch({
          type: RESET_ADDITIONAL_OFF,
        });
        clearInterval(Ref.current);
      } else if ((timerType == "cashBack") & !orderID) {
        dispatch({
          type: RESET_NAT_CASH_BACK,
        });
        clearInterval(CashBackRef.current);
      }
    }
    return {
      finalTotalPrice,
      hours,
      minutes,
      seconds,
    };
  };

  const startTimer = (e, timerType = "addOff") => {
    let { finalTotalPrice, hours, minutes, seconds } = getTimeRemaining(e, timerType);
    if (finalTotalPrice >= 0) {
      if (timerType == "addOff") {
        setTimer(
          (hours > 9 ? hours : "0" + hours) +
            ":" +
            (minutes > 9 ? minutes : "0" + minutes) +
            ":" +
            (seconds > 9 ? seconds : "0" + seconds)
        );
      } else if (timerType == "cashBack") {
        setCashBackTimer(
          (hours > 9 ? hours : "0" + hours) +
            ":" +
            (minutes > 9 ? minutes : "0" + minutes) +
            ":" +
            (seconds > 9 ? seconds : "0" + seconds)
        );
      }
    }
    const id = setInterval(() => {
      let { finalTotalPrice, hours, minutes, seconds } = getTimeRemaining(e, timerType);
      if (finalTotalPrice >= 0) {
        if (timerType == "addOff") {
          setTimer(
            (hours > 9 ? hours : "0" + hours) +
              ":" +
              (minutes > 9 ? minutes : "0" + minutes) +
              ":" +
              (seconds > 9 ? seconds : "0" + seconds)
          );
        } else if (timerType == "cashBack") {
          setCashBackTimer(
            (hours > 9 ? hours : "0" + hours) +
              ":" +
              (minutes > 9 ? minutes : "0" + minutes) +
              ":" +
              (seconds > 9 ? seconds : "0" + seconds)
          );
        }
      }
    }, 1000);
    timerType == "addOff" ? (Ref.current = id) : (CashBackRef.current = id);
  };

  const getDeadTime = (x) => {
    let deadline = new Date();
    deadline.setSeconds(deadline.getSeconds() + x);
    return deadline;
  };

  useEffect(() => {
    if (additionalOff > 0 && additionalOffExpiry) {
      // startTimer(getDeadTime(5), "addOff");
      startTimer(new Date(additionalOffExpiry), "addOff");
    }
    if (eligibleNatCash > 0 && eligibleNatCashExpiry) {
      // startTimer(getDeadTime(10000), "cashBack");
      startTimer(new Date(eligibleNatCashExpiry), "cashBack");
    }
  }, [additionalOffExpiry, eligibleNatCashExpiry]);

  useEffect(() => {
    if (timer === "00:00:00") {
      dispatch(fetchCartContent(cartID, checkoutSelector.pageInitialLoaded));
    }
  }, [timer]);

  return (
    <div className="right-sidebar cart-main sidebarJs">
      <div className="sidebar-cart">
        {eligibleNatCash > 0 && eligibleNatCashExpiry && cashBackTimer && cashBackTimer !== "00:00:00" ? (
          <div className="additional-off">
            <span>ORDER NOW TO GET RS. {eligibleNatCash} CASHBACK</span>
            <span style={{ float: "right" }}>{cashBackTimer}</span>
          </div>
        ) : null}
        {additionalOff > 0 && additionalOffExpiry && timer !== "00:00:00" ? (
          <div className="additional-off">
            <span>ORDER NOW TO GET RS. {additionalOff} EXTRA OFF</span>
            <span style={{ float: "right" }}>{timer}</span>
          </div>
        ) : null}
        <span className="cart-title">
          Your Cart (<span className="itemCountJs">{productDetails.length}</span>)
        </span>
        <span className="cart-title back-btn">
          {/* <img src={makePublicURL("img/back-arrow.png")} className="back-arrow" /> */}
          Cart
          {/* <a
            className={`signout-button ml-auto text-uppercase mobile ${cookies.userPhone ? "" : "d-none"}`}
            onClick={handleLogout}
          >Logout</a> */}
        </span>
        {loading ? (
          <ul className="product-list">
            <LoadingProductEntry />
          </ul>
        ) : (
          <ul className="product-list">
            {freeItems.length > 0 ? (
              <>
                {freeItems.map((product) => (
                  <li
                    key={product.id}
                    className={`product productItemJs px-1 ${
                      product.productDeliverabilityError && addressDeliverabilityError ? "non-deliverable-product" : ""
                    }`}
                  >
                    <ProductEntry
                      productDetails={product}
                      currency={currency}
                      onDelete={updateQuantity}
                      isDiscountCode={finalDiscountCode}
                    />
                  </li>
                ))}
              </>
            ) : null}

            {productDetails.map((product, index) => {
              return (
                <li
                  key={product.id}
                  className={`product productItemJs px-1 ${
                    product.productDeliverabilityError && addressDeliverabilityError ? "non-deliverable-product" : ""
                  }`}
                >
                  <ProductEntry
                    productDetails={product}
                    currency={currency}
                    onDelete={updateQuantity}
                    isDiscountCode={finalDiscountCode}
                    isDiscountApplied={discountReason ? true : false}
                  />
                  {/* <div className="cartitem-actions cartActionJs">
                      <div className="cartitem-quantity cartQuantityJs">
                        <button
                          className="minus minusCountJs"
                          type="button"
                          onClick={() => updateQuantity(product, -1)}
                        />
                        <input
                          type="text"
                          className="product_value productValueJs"
                          readOnly
                          value={product.productQuantity}
                        />
                        <button className="plus plusCountJs" type="button" onClick={() => updateQuantity(product, 1)} />
                      </div>
                      <div>
                        <button
                          style={{ backgroundColor: "#fff" }}
                          type="button"
                          onClick={() => updateQuantity(product, -product.productQuantity)}
                        >
                          <i className="fa fa-trash-o" style={{ color: "#CD5555" }} aria-hidden="true"></i>
                        </button>
                      </div>
                    </div> */}
                </li>
              );
            })}
          </ul>
        )}

        <div className="make-git-section">
          <div className="summary-row total-row">
            <span className="total-name">Make a Gift</span>
            {isGift && edit ? (
              <button onClick={editGift} className="edit-icon">
                <FormOutlined style={{ fontSize: 20 }} />
              </button>
            ) : (
              <Switch className={isGift ? "gift-toggle" : ""} checked={isGift} onChange={makeGiftToggle} />
            )}
          </div>
          {showGiftForm ? (
            <div className="gift-form">
              <form className="form-gift " autoComplete="off">
                <label id="discount-error" className="gift-label">
                  From:
                </label>

                <input
                  type="text"
                  name="sender"
                  disabled={noteditForm}
                  className="field-input"
                  placeholder="Enter your name"
                  maxLength={50}
                  value={sender}
                  onChange={(e) => setSender(e.target.value)}
                />

                <label id="discount-error" className="gift-label">
                  To:
                </label>

                <input
                  type="text"
                  name="sender"
                  disabled={noteditForm}
                  className="field-input"
                  placeholder="Enter recepient name"
                  maxLength={50}
                  onChange={(e) => setRecepient(e.target.value)}
                  value={recepient}
                />

                <label id="discount-error" className="gift-label">
                  Gift message (max 500 char):
                </label>

                <textarea
                  type="text"
                  name="sender"
                  disabled={noteditForm}
                  className="field-input"
                  placeholder="Your message"
                  maxLength={500}
                  value={isgiftMessage}
                  onChange={(e) => setGiftMessage(e.target.value)}
                />

                <Checkbox
                  className="invoice_input"
                  onChange={(e) => setSendInvoice(e.target.checked)}
                  checked={sendInvoice}
                  disabled={noteditForm}
                >
                  Don't send the invoice
                </Checkbox>
                {/* {
    !noteditForm ? (
      <button
        ref={giftButtonRef}
        className={"form-btn"}
        type="submit"
        onClick={saveGiftData}
        style={{ width: "100%" }}
      >
        Save Gift Message
      </button>
    ) : null
  } */}
              </form>
            </div>
          ) : null}
        </div>

        <div className="discount-code-section">
          <form
            ref={discountFormRef}
            className="form-discount discountValidateJS"
            onSubmit={discountHandler}
            autoComplete="off"
          >
            <img src={makePublicURL("img/discount.png")} alt="" className="discount-ico" />
            <div className="discount-code discountVilidBox">
              <span className="input-wrap">
                <input
                  ref={discountInputRef}
                  type="text"
                  name="discount"
                  className="field-input discountValid"
                  placeholder="Discount"
                  onChange={handleDiscountCodeChange}
                  onKeyUp={handleDiscountCodeChange}
                  onInput={handleDiscountCodeChange}
                  onBlur={handleDiscountCodeChange}
                  onClick={(e) => {
                    if (window.matchMedia("(max-width: 767px)").matches) {
                      setTimeout(() => {
                        let absoluteTopPosition = discountInputRef.current.getBoundingClientRect().top;
                        window.scrollBy({
                          top: absoluteTopPosition - 50,
                          left: 0,
                          behavior: "smooth",
                        });
                      }, 500);
                    }
                  }}
                  value={discountCode}
                  disabled={discountInputDisabled}
                />

                <label id="discount-error" className="error" htmlFor="discount">
                  {discountCodeErrorMessage}
                </label>

                <span ref={removeValJsRef} className="removeValJs remove-value" onClick={handleRemoveDiscount} />
              </span>
            </div>

            {!discountReason || discountCodeErrorMessage ? (
              <button className="apply-btn applyBtnJs">
                {/* {discountLoading ? <Spin indicator={antIcon} /> : <span className="checkmark" />} */}
                {discountLoading ? <Spin indicator={antIcon} /> : <span className="applymark"> APPLY</span>}
              </button>
            ) : null}
          </form>
        </div>

        {phoneNumber && mode === "verifiedUser" && maxApplicableNatCash > 0 && natCash > 0 ? (
          <div className="discount-code-section">
            <div className="price-title" style={{ marginBottom: 0 }}>
              Apply {currency + (maxApplicableNatCash > natCash ? natCash : maxApplicableNatCash)} NatCash
              <span style={{ float: "right" }}>
                {/* {natCashCheckData.loading ? (
                  <Spin indicator={antIcon} />
                ) : ( */}
                <Switch
                  className={natCashCheckData.applied ? "gift-toggle" : ""}
                  checked={natCashCheckData.applied}
                  onChange={natCashHandler}
                />
                {/*  )} */}
              </span>
              <label id="discount-error" className="error">
                {natCashCheckData.errorMsg}
              </label>
            </div>
            <div className="summary-row subtotal-row payment-section ">
              <span className="subtotal-name">Available NatCash {currency + natCash}</span>
            </div>
          </div>
        ) : null}

        {isLoading ? (
          <BillDetailsSkeletonLoader />
        ) : (
          <div className="order-summary-section">
            <span className="price-title">Bill Details</span>

            <div className="summary-row subtotal-row payment-section ">
              <span className="subtotal-name">Total MRP</span>
              <span className="product-price">
                {currency} <span className="priceItemsTotalJs">{subTotalPrice}</span>
              </span>
            </div>

            {/* <div className="summary-row subtotal-row">
            <span className="subtotal-name">Item Total</span>
            <span className="product-price">
              {currency} <span className="priceItemsTotalJs">{total + discountPrice - shipping}</span>
            </span>
          </div> */}

            <div className="summary-row mrpdiscount-row payment-section">
              <span className="total-name total-discount"> Total Discount </span>
              {/* {discountReason && !discountCodeErrorMessage ? (
                <span className="total-name total-discount"> Coupon Discount </span>
              ) : (
                <span className="total-name total-discount"> Total Discount </span>
              )} */}
              <p className="toltip-wrapper"> i</p>

              <div className="discount-wrapper ">
                <div className="discount-tooltip">
                  <div className="discount-tooltip-header">Total discount breakup:</div>

                  {discountReason && !discountCodeErrorMessage ? (
                    <div
                      ref={discountSectionRef}
                      className="summary-row discount-row discountValueJs payment-section active"
                    >
                      <span className="subtotal-name">Coupon Discount</span>
                      <span className="product-price ">
                        - {currency} <span className="discountJs">{discountAmount}</span>
                      </span>
                    </div>
                  ) : (
                    <div className="summary-row subtotal-row payment-section">
                      <span className="subtotal-name">Discount on MRP</span>
                      <span className="product-price">
                        <span className="shippingJs ">- Rs. {discountAmount}</span>
                      </span>
                    </div>
                  )}

                  {/* {paymentSelector.paymentMode === "online" && !isMobile && paymentCharges && paymentCharges !== 0 ? (
                    <div className="summary-row subtotal-row payment-section">
                      <span className="subtotal-name">Online Payment Discount</span>
                      <span className="product-price">
                        <span className="shippingJs ">- Rs. {paymentCharges * -1}</span>
                      </span>
                    </div>
                  ) : null} */}

                  {promotionCosts && promotionCosts.length > 0
                    ? promotionCosts.map((item, index) => (
                        <div key={index} className="summary-row subtotal-row payment-section ">
                          <span className="subtotal-name">
                            {item.promotion_quantity} {item.promotion_quantity > 1 ? `items` : `item`} at {currency}{" "}
                            {item.promotion_price} applied
                          </span>
                          <span className="product-price">
                            - {currency} <span className="priceItemsTotalJs">{item.promotion_discount}</span>
                          </span>
                        </div>
                      ))
                    : null}

                  {additionalOff > 0 ? (
                    <div className="summary-row subtotal-row payment-section ">
                      <span className="subtotal-name">Additional Discount</span>
                      <span className="product-price">
                        - {currency} <span className="priceItemsTotalJs">{additionalOff}</span>
                      </span>
                    </div>
                  ) : null}

                  {natCashApplied > 0 ? (
                    <div className="summary-row subtotal-row payment-section ">
                      <span className="subtotal-name">NatCash</span>
                      <span className="product-price">
                        - {currency} <span className="priceItemsTotalJs">{natCashApplied}</span>
                      </span>
                    </div>
                  ) : null}

                  {/* {promotionDiscountAmount && promotionDiscountAmount > 0 ? (
                    <div className="summary-row subtotal-row payment-section ">
                      <span className="subtotal-name">Additional Discount</span>
                      <span className="product-price">
                        - {currency} <span className="priceItemsTotalJs">{additionalOff}</span>
                      </span>
                    </div>
                  ) : null} */}
                </div>
              </div>

              <span className="product-price discount-color">
                - {currency}{" "}
                <span className="priceItemsTotalJs">
                  {discountAmount +
                    additionalOff +
                    natCashApplied +
                    promotionCosts.reduce((sum, item) => (sum += item.promotion_discount), 0)}
                  {/* {paymentSelector.paymentMode === CARD_PAYMENT && !isMobile
                  ? discountAmount - paymentCharges
                  : discountAmount} */}
                </span>
              </span>
            </div>

            {/* {additionalOff > 0 ? (
              <div className="summary-row subtotal-row payment-section ">
                <span className="subtotal-name">Additional Discount</span>
                <span className="product-price">
                  - {currency} <span className="priceItemsTotalJs">{additionalOff}</span>
                </span>
              </div>
            ) : null} */}

            {/* {natCashApplied > 0 ? (
              <div className="summary-row subtotal-row payment-section ">
                <span className="subtotal-name">NatCash</span>
                <span className="product-price">
                  - {currency} <span className="priceItemsTotalJs">{natCashApplied}</span>
                </span>
              </div>
            ) : null} */}

            {/* <div className="summary-row mrpdiscount-row payment-section">
            <span className="total-name">Discount on MRP</span>
            <span className="product-price discount-color">
              - {currency} <span className="priceItemsTotalJs">{mrpDiscount}</span>
            </span>
          </div>

          <div ref={discountSectionRef} className="summary-row discount-row discountValueJs payment-section">
            <span className="total-name">Coupon Discount</span>
            <span className="product-price discount-color">
              - {currency} <span className="discountJs">{discountPrice}</span>
            </span>
          </div> */}

            <div className="summary-row subtotal-row payment-section">
              <span className="subtotal-name">
                {paymentSelector.paymentMode === "cod" && !isMobile ? "Shipping & COD Charges" : "Shipping Charges"}
                <div className="toltip-wrapper">
                  {" "}
                  i
                  {!paymentSelector.paymentMode || paymentSelector.paymentMode === "online" || isMobile ? (
                    <p className="toltip">Shipping & payment charges are based on address and payment method.</p>
                  ) : (
                    <span className="toltip">
                      <div className="discount-tooltip-header">Shipping & COD breakup:</div>

                      <div className="summary-row subtotal-row payment-section">
                        <span className="subtotal-name">Shipping Charge</span>
                        <span className="product-price">
                          <span className="shippingJs ">Rs. {shipping}</span>
                        </span>
                      </div>
                      <div className="summary-row subtotal-row payment-section">
                        <span className="subtotal-name">COD Charge</span>
                        <span className="product-price">
                          <span className="shippingJs ">Rs. {paymentCharges}</span>
                        </span>
                      </div>
                    </span>
                  )}
                </div>
              </span>

              {shipping || paymentCharges ? (
                <span className="product-price">
                  {currency}
                  <span className="shippingJs">{isMobile ? shipping : shipping + paymentCharges}</span>
                </span>
              ) : (
                <span className="product-price">
                  <span className="shippingJs strikeshipping">Rs. 50</span>{" "}
                  <span className="discount-color">FREE</span>
                </span>
              )}
            </div>

            <div className="summary-row total-row">
              <span className="total-name">To Pay</span>
              <span className="total-price">
                {currency}
                <span className="priceTotalJs">
                  {/* {finalTotalPrice} */}
                  {isMobile ? finalTotalPrice - paymentCharges : finalTotalPrice}
                </span>
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
