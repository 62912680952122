import React, { useEffect, useRef, useState, useLayoutEffect } from "react";
import { Drawer, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import { useSelector, useDispatch } from "react-redux";
import { addClass, makePublicURL, removeClass } from "../../helpers";
import {
  ACTIVATE_ADDRESS,
  ACTIVATE_PAYMENT,
  DISABLE_ADDRESS,
  REMOVE_OVERLAY,
  SHOW_MODAL,
} from "../../store/checkout/actions";
import { fetchCartContent } from "../../store/cart/actions";

import {
  CANDIDATE_ADDRESS,
  INTERESTED_ADDRESS,
  REVIEW_CART,
  SELECTED_ADDRESS,
  UPDATE_ADDRESS_LOADING,
} from "../../store/address/actions";
import { sendEvent, sendCheckoutOptionGoogleAnalytics, sendAddressSelectionToMoengage } from "../../helpers/ga";
import { pushEventsToCt } from "../../helpers/ct";

const antIcon = <LoadingOutlined style={{ fontSize: 30, color: "rgb(161, 166, 172)" }} spin />;

export default function AddressEntry(props) {
  const dispatch = useDispatch();
  const checkoutSelector = useSelector((state) => state.checkout);
  const addressSelector = useSelector((state) => state.address);
  const loginSelector = useSelector((state) => state.login);
  const { addressDeliverabilityError, cartId, productDetails } = useSelector((state) => state.cart);
  const { canAddressBeSelected } = addressSelector;
  const { mode } = loginSelector;
  const {
    addressID,
    addressType,
    addressLocation,
    zip,
    city,
    apartmentName,
    pincode,
    enableEdit,
    fullName,
    alternateMobileNumber,
    addressLandmark,
    addressStreet,
  } = props.addressDetails;
  const addressItemRef = useRef();
  const [showPopover, setShowPopover] = useState(false);
  const [addressString, setAddressString] = useState("");
  const [entryLoading, setEntryLoading] = useState(false);

  const handleSelected = (e) => {
    if (e.target.id !== "editAddress") {
      setEntryLoading(true);
      sendCheckoutOptionGoogleAnalytics(3, "nonDefaultAddressSelected", productDetails);
      dispatch(fetchCartContent(cartId, true, props.addressDetails.city));
      dispatch({ type: INTERESTED_ADDRESS, payload: props.addressDetails });
      pushEventsToCt("Shipping Address Selected", {
        "Default Address": props.addressDetails.default,
        "New Address": false,
        Pincode: props.addressDetails.pincode,
        City: props.addressDetails.city,
        State: props.addressDetails.state,
        "User Type": mode === "verifiedUser" ? "Verified User" : "Guest User",
      });
    }
  };

  const handleEditAddress = (e) => {
    e.preventDefault();
    dispatch({ type: CANDIDATE_ADDRESS, payload: props.addressDetails });
    dispatch({ type: SHOW_MODAL });
  };

  const handleSelectAddress = () => {
    setEntryLoading(false);
    if (addressSelector.interestedAddress) {
      setShowPopover(false);
      if (addressSelector.interestedAddress.addressID == addressID) {
        if (!addressDeliverabilityError) {
          removeClass(addressItemRef, "is-disabled");
          addClass(addressItemRef, "selected");
          dispatch({ type: ACTIVATE_PAYMENT });
          dispatch({ type: DISABLE_ADDRESS });
          // sendEvent("addressSelected", "User Selected Address", "Click");

          dispatch({ type: SELECTED_ADDRESS, payload: props.addressDetails });
          sendCheckoutOptionGoogleAnalytics(4, "noDeliveryErrorInNonDefaultAddress", productDetails);
          const oldVsNew = addressSelector.interestedAddress.enableEdit ? "new-selected" : "old-selected";
          sendAddressSelectionToMoengage(addressSelector.interestedAddress, oldVsNew);
          dispatch({ type: REMOVE_OVERLAY, payload: { removeOverlay: true } });
        } else {
          if (window.matchMedia("(max-width: 767px)").matches) {
            // setTimeout(() => {
            dispatch({ type: ACTIVATE_ADDRESS });
            dispatch({ type: REVIEW_CART });
            // }, 100);
          } else {
            setShowPopover(true);
          }
        }
        dispatch({ type: UPDATE_ADDRESS_LOADING, payload: false });
      }
    }
  };

  useEffect(() => {
    if (checkoutSelector.disableAllAddresses) {
      addClass(addressItemRef, "is-disabled");
      removeClass(addressItemRef, "selected");
    } else {
      removeClass(addressItemRef, "is-disabled");
    }
  }, [checkoutSelector]);

  useEffect(() => {
    if (canAddressBeSelected) {
      handleSelectAddress();
    }
  }, [addressSelector.interestedAddress, canAddressBeSelected]);

  useLayoutEffect(() => {
    let addresToDisplay = "";

    if (apartmentName) addresToDisplay += apartmentName + ", ";
    if (addressStreet) addresToDisplay += addressStreet + ", ";
    if (addressLocation) addresToDisplay += addressLocation + ", ";

    setAddressString(addresToDisplay);
  }, [apartmentName, addressStreet, addressLocation]);

  return (
    <>
      <div
        ref={addressItemRef}
        className="notify-wrap add-address-section addressItemJs homeItemJs. site-drawer-render-in-current-wrapper"
        data-elemnt-indedit="homeItemJs"
        style={{ overflowY: "auto", overflowX: "hidden", minHeight: showPopover ? 150 : "" }}
      >
        <Drawer
          // title="Basic Drawer"
          placement="right"
          closable={true}
          onClose={() => setShowPopover(false)}
          visible={showPopover}
          getContainer={false}
          style={{ position: "absolute" }}
        >
          <p style={{ paddingTop: 15 }}>Some products you have added are outside of servicable area.</p>
          <div>
            {/* {enableEdit ? (
              <button>
                <i
                  className="fa fa-pencil"
                  style={{ color: "green", fontSize: 15 }}
                  aria-hidden="true"
                  id="editAddress"
                  onClick={handleEditAddress}
                  data-toggle="modal"
                  data-direction="left"
                  data-target="#mapModal"
                ></i>
                &nbsp; Edit
              </button>
            ) : (
                <></>
              )} */}
            <button
              className="ml-2 reviewCartButton"
              onClick={(e) => {
                setTimeout(() => {
                  dispatch({ type: ACTIVATE_ADDRESS });
                  dispatch({ type: REVIEW_CART });
                }, 100);
                setShowPopover(false);
              }}
            >
              Review Cart
            </button>
          </div>
        </Drawer>

        <div className="container p-0" style={{ cursor: "pointer" }} onClick={handleSelected}>
          <div className="row">
            <div className="col-3">
              {/* <img src={makePublicURL("img/address.png")} alt="" className="address-img" /> */}
              {/* <i class="fas fa-map-marker"></i> */}
              {entryLoading ? (
                <Spin indicator={antIcon} />
              ) : (
                <i
                  className="fa fa-map-marker"
                  aria-hidden="true"
                  style={{ fontSize: 40, color: "rgb(161, 166, 172)" }}
                ></i>
              )}
            </div>
            <div className="col-9 pl-0">
              <span className="title addressTitleJs">
                <span>{fullName}</span>
                {mode === "verifiedUser" && (
                  <i
                    className="fa fa-pencil pl-2"
                    style={{ color: "green", fontSize: 15 }}
                    aria-hidden="true"
                    id="editAddress"
                    onClick={handleEditAddress}
                    data-toggle="modal"
                    data-direction="left"
                    data-target="#mapModal"
                  ></i>
                )}
              </span>
              {addressString ? <span className="subtitle addressSubTitleJs mb-1">{addressString}</span> : null}
              <span className="subtitle addressSubTitleJs mb-1">{`${city}-${zip ? zip : pincode}`}</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
