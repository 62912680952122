export const pushEventsToCt = (eventName, data) => {
  try {
    const eventData = {
      "Page Type": "Checkout",
      "Page Url": window.location.pathname,
      "Checkout Token": new URL(window.location).searchParams.get("cart_id"),
      ...data,
    };
    console.log("pushEventsToCt", eventName, eventData);
    if (window.clevertap) {
      window.clevertap.event.push(eventName, eventData);
    }
  } catch (err) {
    console.log("ct err", err);
  }
};

export const updateUserOnCT = (data) => {
  try {
    if (window.clevertap) {
      window.clevertap.onUserLogin.push(data);
    }
  } catch (err) {
    console.log("ct err", err);
  }
};
