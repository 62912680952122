import fetchFn from "./fetch";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

export let cartAPI;
export let customerAPI;
export let authAPI;
export let orderAPI;
export let promotionsAPI;
export let razorpayKey;
export let cityStateAPI;
export let sentryInit;
export let newPaymentFlow = false;

if (true) {
  // if (true) {
  cartAPI = fetchFn(`https://ecomm.api.nathabit.in`);
  customerAPI = fetchFn(`https://customers.api.nathabit.in`);
  authAPI = fetchFn(`https://authorize.api.nathabit.in`);
  orderAPI = fetchFn(`https://orders.api.nathabit.in`);
  promotionsAPI = fetchFn(`https://promotions.api.nathabit.in`);
  razorpayKey = "rzp_live_hLQM0IKKUc2K8G";
  cityStateAPI = fetchFn("https://pincode-city.ms.nathabit.in/api/get/city/");
  sentryInit = () => {
    Sentry.init({
      dsn: "https://c2175d11dd944759ba3a6875244f69e6@o624721.ingest.sentry.io/5761754",
      integrations: [new Integrations.BrowserTracing()],
      environment: "production",
      sendDefaultPii: true,
    });
  };
} else {
  cartAPI = fetchFn(`https://ecomm.api.nathabit.in`);
  customerAPI = fetchFn(`https://customer.api.stage.nathabit.in`);
  authAPI = fetchFn(`https://authorize.api.stage.nathabit.in`);
  orderAPI = fetchFn(`https://orders.api.stage.nathabit.in`);
  promotionsAPI = fetchFn(`https://promotions.api.nathabit.in`);
  razorpayKey = "rzp_test_5o61P8hRRbK7b5";
  cityStateAPI = fetchFn("https://pincode-city.ms.nathabit.in/api/get/city/");
  sentryInit = () => {
    Sentry.init({
      dsn: "https://78fe1d289d4f472e87e272f10b1f3b3b@o289426.ingest.sentry.io/5690230",
      environment: "staging",
    });
  };
}
