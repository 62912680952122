import { getLatitudeAndLongitude } from "../../helpers";
import { CLOSE_MODAL } from "../checkout/actions";
import { fetchCartContent } from "../cart/actions";
import { saveNewAddressInLocalStorage, updateAddressInLocalStorage } from "../../helpers/addressStorage";
import { sendSubCheckoutOptionGA } from "../../helpers/ga";
import { authAPI } from "../../helpers/api";
import { sendDataToSlackForAddress } from "../../helpers/sendDatatoSlack";
import { pushEventsToCt } from "../../helpers/ct";

export const ADD_ADDRESS = "addAddress";
export const ADD_ADDRESS_LOADING = "addAddressLoading";
export const SELECTED_ADDRESS = "selectedAddress";
export const INTERESTED_ADDRESS = "interestedAddress";
export const CANDIDATE_ADDRESS = "editCandidateAddress";
export const GET_EXISTING_ADDRESS = "getExistingAddresses";
export const GET_LOCAL_ADDRESS = "getLocalAddress";
export const UPDATE_ADDRESS = "updateAddress";
export const DELETE_ADDRESS = "deleteAddress";
export const REVIEW_CART = "reviewCart";
export const TOGGLE_ADDRESS_SELECTION_TRUE = "toggleAddressSelectionTrue";
export const TOGGLE_ADDRESS_SELECTION_FALSE = "toggleAddressSelectionFalse";
export const SET_FIRST_MOUNT_ADDRESS = "setFirstMountAddress";
export const UPDATE_ADDRESS_LOADING = "addressLoading";

export const addNewAddress = (addressDetails, locationDetails, cartId) => async (dispatch) => {
  dispatch({ type: CLOSE_MODAL });
  let newAddressDetails = {
    ...locationDetails,
    ...addressDetails,
    addressID: Math.random()
      .toString(36)
      .replace(/[^a-z]+/g, "")
      .substr(0, 15),
  };

  try {
    const { lat, lng } = await getLatitudeAndLongitude(newAddressDetails.addressLocation);
    newAddressDetails = {
      ...newAddressDetails,
      lat,
      lng,
    };
  } catch (e) {
    console.error(e);
  }
  dispatch(fetchCartContent(cartId, true, locationDetails.city, newAddressDetails.addressID));

  sendSubCheckoutOptionGA(3, "addedNewAddress");

  saveNewAddressInLocalStorage(newAddressDetails);
  dispatch({
    type: INTERESTED_ADDRESS,
    payload: {
      ...newAddressDetails,
    },
  });

  dispatch({
    type: ADD_ADDRESS,
    payload: {
      ...newAddressDetails,
    },
  });
};

export const addNewAddressV2 =
  (addressDetails, locationDetails, cartId, shpID, editAddress = false) =>
  async (dispatch) => {
    dispatch({
      type: ADD_ADDRESS_LOADING,
      payload: {
        loading: true,
      },
    });
    let newAddressDetails = {
      ...locationDetails,
      ...addressDetails,
      city: locationDetails.city || addressDetails.city,
    };

    !editAddress &&
      (newAddressDetails.addressID = Math.random()
        .toString(36)
        .replace(/[^a-z]+/g, "")
        .substr(0, 15));

    try {
      const { lat, lng } = await getLatitudeAndLongitude(newAddressDetails.addressLocation);
      newAddressDetails = {
        ...newAddressDetails,
        lat,
        lng,
        customer: shpID,
      };
    } catch (e) {
      newAddressDetails = {
        ...newAddressDetails,
        customer: shpID,
      };
      console.error(e);
    }
    dispatch(fetchCartContent(cartId, true, locationDetails.city, newAddressDetails.addressID));

    const { data, statusCode } = editAddress
      ? await authAPI.put(`/v1/user/addresses/`, newAddressDetails)
      : await authAPI.post(`/v1/user/addresses/`, newAddressDetails);
    if (statusCode === 200) {
      dispatch({
        type: ADD_ADDRESS_LOADING,
        payload: {
          loading: false,
        },
      });
      dispatch({ type: CLOSE_MODAL });
      sendSubCheckoutOptionGA(3, "addedNewAddress");
      newAddressDetails = {
        ...newAddressDetails,
        addressID: data.id,
        editAddressData: data.edit_address_data,
      };
      dispatch({
        type: INTERESTED_ADDRESS,
        payload: {
          ...newAddressDetails,
        },
      });

      dispatch({
        type: ADD_ADDRESS,
        payload: {
          ...newAddressDetails,
        },
      });

      editAddress &&
        dispatch({
          type: DELETE_ADDRESS,
          payload: {
            addressID: addressDetails.addressID,
          },
        });
    } else {
      sendDataToSlackForAddress(newAddressDetails, data, statusCode);
    }
    pushEventsToCt(editAddress ? "Shipping Address Edited" : "Shipping Address Added", {
      Pincode: locationDetails.pincode,
      City: locationDetails.city,
      State: locationDetails.state,
      "User Type": "Verified User",
    });
  };

export const updateAddress = (addressDetails, locationDetails, cartId) => async (dispatch) => {
  dispatch({ type: CLOSE_MODAL });

  const newAddressDetails = {
    ...locationDetails,
    ...addressDetails,
  };

  sendSubCheckoutOptionGA(3, "updatedNewAddress");
  dispatch(fetchCartContent(cartId, true, locationDetails.city));
  updateAddressInLocalStorage(newAddressDetails);

  dispatch({
    type: INTERESTED_ADDRESS,
    payload: newAddressDetails,
  });

  dispatch({ type: UPDATE_ADDRESS, payload: newAddressDetails });
};

export const addNewGuestAddress = (addressDetails, locationDetails, cartId, customer) => async (dispatch) => {
  dispatch({
    type: ADD_ADDRESS_LOADING,
    payload: {
      loading: true,
    },
  });
  let newAddressDetails = {
    ...locationDetails,
    ...addressDetails,
    addressID: Math.random()
      .toString(36)
      .replace(/[^a-z]+/g, "")
      .substr(0, 15),
  };

  try {
    const { lat, lng } = await getLatitudeAndLongitude(newAddressDetails.addressLocation);
    newAddressDetails = {
      ...newAddressDetails,
      lat,
      lng,
      customer: customer,
    };
  } catch (e) {
    newAddressDetails = {
      ...newAddressDetails,
      customer: customer,
    };
    console.error(e);
  }
  dispatch(fetchCartContent(cartId, true, locationDetails.city, newAddressDetails.addressID));

  const { data, statusCode } = await authAPI.post(`/v1/guest/addresses/`, newAddressDetails);
  if (statusCode === 200) {
    let existing_ids = JSON.parse(localStorage.getItem("guestAddressesID")) || [];
    existing_ids.push(data.id);
    localStorage.setItem("guestAddressesID", JSON.stringify(existing_ids));
    sendSubCheckoutOptionGA(3, "addedNewAddress");
    newAddressDetails = {
      ...newAddressDetails,
      addressID: data.id,
    };
    dispatch({
      type: ADD_ADDRESS_LOADING,
      payload: {
        loading: false,
      },
    });
    dispatch({ type: CLOSE_MODAL });
    dispatch({
      type: INTERESTED_ADDRESS,
      payload: {
        ...newAddressDetails,
      },
    });

    dispatch({
      type: ADD_ADDRESS,
      payload: {
        ...newAddressDetails,
      },
    });
  } else {
    sendDataToSlackForAddress(newAddressDetails, data, statusCode);
  }
  pushEventsToCt("Shipping Address Added", {
    "User Type": "Guest User",
  });
};
